var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"video-player-advanced-controls"},[(_vm.showInfo)?_c('div',{staticClass:"text-center p-3 w-100"},[_vm._v(" The advanced controls allow you to set an in point "),_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"arrow-counterclockwise"}}),_vm._v(" LOOP IN, set an out point "),_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"arrow-counterclockwise"}}),_vm._v(" LOOP OUT and toggle looping on and off "),_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"arrow-repeat"}}),_vm._v(" Toggle. ")],1):_vm._e(),_c('div',{staticClass:"bg-grey-4"},[_c('b-button-group',{staticClass:"control-group w-100",attrs:{"flat":"","spread":""}},[_c('b-button',{on:{"click":_vm.setLoopIn}},[_c('b-icon',{attrs:{"icon":"geo-alt-fill"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" Loop In ")]),(_vm.loop.in !== null)?_c('span',{style:({
            backgroundColor: _vm.$store.getters.brandColor,
            color: 'white',
            padding: '0.5rem',
          })},[_vm._v(" "+_vm._s(_vm.convertTime(_vm.loop.in))+" ")]):_vm._e()],1),_c('b-button',{style:({
          backgroundColor: _vm.loop.active ? _vm.$store.getters.brandColor : '',
          color: _vm.loop.active ? 'white' : '',
        }),attrs:{"disabled":!_vm.loop.out || !_vm.loop.in},on:{"click":function($event){_vm.loop.active = !_vm.loop.active}}},[_c('b-icon',{style:({ fontSize: '.9rem', color: _vm.loop.active ? 'white' : '' }),attrs:{"icon":"arrow-counterclockwise"}}),_c('span',{staticClass:"ml-1"},[_vm._v(" Toggle ")])],1),_c('b-button',{attrs:{"disabled":_vm.loop.in === null},on:{"click":_vm.setLoopOut}},[_c('b-icon',{attrs:{"icon":"geo-alt-fill"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" Loop Out ")]),(_vm.loop.out !== null)?_c('span',{style:({
            backgroundColor: _vm.$store.getters.brandColor,
            color: 'white',
            padding: '0.5rem',
          })},[_vm._v(" "+_vm._s(_vm.convertTime(_vm.loop.out))+" ")]):_vm._e()],1),_c('b-button',{attrs:{"title":"Info"},on:{"click":function($event){_vm.showInfo = !_vm.showInfo}}},[_c('b-icon',{style:({
            fontSize: '.9rem',
            color: _vm.showInfo ? _vm.$store.getters.brandColor : 'white',
          }),attrs:{"icon":"question-circle"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }