<template>
  <section class="video-player-advanced-controls">
    <div v-if="showInfo" class="text-center p-3 w-100">
      The advanced controls allow you to set an in point
      <b-icon icon="arrow-counterclockwise" class="mx-1" />
      LOOP IN, set an out point
      <b-icon icon="arrow-counterclockwise" class="mx-1" />
      LOOP OUT and toggle looping on and off
      <b-icon icon="arrow-repeat" class="mx-1" />
      Toggle.
    </div>
    <div class="bg-grey-4">
      <b-button-group flat spread class="control-group w-100">
        <b-button @click="setLoopIn">
          <b-icon icon="geo-alt-fill" />
          <span class="ml-2"> Loop In </span>
          <span
            v-if="loop.in !== null"
            :style="{
              backgroundColor: $store.getters.brandColor,
              color: 'white',
              padding: '0.5rem',
            }"
          >
            {{ convertTime(loop.in) }}
          </span>
        </b-button>
        <b-button
          :style="{
            backgroundColor: loop.active ? $store.getters.brandColor : '',
            color: loop.active ? 'white' : '',
          }"
          :disabled="!loop.out || !loop.in"
          @click="loop.active = !loop.active"
        >
          <b-icon icon="arrow-counterclockwise" :style="{ fontSize: '.9rem', color: loop.active ? 'white' : '' }" />
          <span class="ml-1"> Toggle </span>
        </b-button>
        <b-button :disabled="loop.in === null" @click="setLoopOut">
          <b-icon icon="geo-alt-fill" />
          <span class="ml-2"> Loop Out </span>
          <span
            v-if="loop.out !== null"
            :style="{
              backgroundColor: $store.getters.brandColor,
              color: 'white',
              padding: '0.5rem',
            }"
          >
            {{ convertTime(loop.out) }}
          </span>
        </b-button>
        <b-button title="Info" @click="showInfo = !showInfo">
          <b-icon
            icon="question-circle"
            :style="{
              fontSize: '.9rem',
              color: showInfo ? $store.getters.brandColor : 'white',
            }"
          />
        </b-button>
      </b-button-group>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    loop: {
      type: Object,
      required: true,
    },
    currentVideoTime: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showInfo: false,
    }
  },
  methods: {
    setLoopIn() {
      this.loop.in = this.loop.in ? null : this.currentVideoTime
      if (this.loop.out !== null && this.loop.in > this.loop.out) {
        this.loop.out = null
      }
    },
    setLoopOut() {
      if (this.loop.out) {
        this.loop.out = null
      } else {
        this.loop.out = this.currentVideoTime
        this.loop.active = true
      }
    },
    convertTime(time) {
      var pSeconds = Number(time)
      var h = Math.floor(pSeconds / 3600)
      var m = Math.floor((pSeconds % 3600) / 60)
      var s = Math.floor((pSeconds % 3600) % 60)
      return (h > 0 ? (h < 10 ? "0" + h : h) + ":" : "") + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s)
    },
  },
  beforeDestroy() {
    this.loop.active = false
  },
}
</script>

<style lang="scss" scoped></style>
